
import http from "@/common/http";
import { getLabelByValue, clubHpChangeTypes, gameTypes } from "@/common/head";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { pagConfig, searchCol } from "@/config/app";
import { dateIsBetween, getPageOffset } from "@/common/utils";
import { Dayjs } from "dayjs";

const columns = [
  // {
  //   title: "序号",
  //   width: 100,
  //   dataIndex: "index",
  //   fixed: "left",
  // },

  {
    title: "俱乐部ID",
    width: 100,
    dataIndex: "clubId",
    ellipsis: true,
  },

  {
    title: "俱乐部名称",
    width: 180,
    dataIndex: "clubName",
    ellipsis: true,
  },

  {
    title: "游戏类型",
    width: 140,
    dataIndex: "gameType",
    ellipsis: true,
  },

  {
    title: "邀请码<房间号>",
    width: 140,
    dataIndex: "roomCode",
    ellipsis: true,
  },

  {
    title: "变化类型",
    width: 140,
    dataIndex: "changeType",
    ellipsis: true,
  },

  {
    title: "变化前体力",
    width: 140,
    dataIndex: "beforeHp",
    ellipsis: true,
  },

  {
    title: "变化的体力",
    width: 140,
    dataIndex: "changeHp",
    ellipsis: true,
  },

  {
    title: "变化后体力",
    width: 140,
    dataIndex: "afterHp",
    ellipsis: true,
  },

  {
    title: "成员userID",
    width: 150,
    dataIndex: "memberId",
    ellipsis: true,
  },

  {
    title: "成员游戏ID",
    width: 150,
    dataIndex: "memberMaskId",
    ellipsis: true,
  },

  {
    title: "成员昵称",
    width: 150,
    dataIndex: "memberName",
    ellipsis: true,
  },

  {
    title: "成员头像",
    width: 100,
    dataIndex: "memberIcon",
    ellipsis: true,
  },

  {
    title: "关联者userID",
    width: 150,
    dataIndex: "relationId",
    ellipsis: true,
  },

  {
    title: "关联者游戏ID",
    width: 150,
    dataIndex: "relationMaskId",
    ellipsis: true,
  },

  {
    title: "关联者昵称",
    width: 150,
    dataIndex: "relationName",
    ellipsis: true,
  },

  {
    title: "关联者头像",
    width: 150,
    dataIndex: "relationIcon",
    ellipsis: true,
  },

  {
    title: "备注",
    width: 160,
    dataIndex: "remark",
    // fixed: "right",
  },

  {
    title: "变化时间",
    width: 180,
    dataIndex: "createTime",
    fixed: "right",
  },

  // {
  //   title: "操作",
  //   key: "operation",
  //   dataIndex: "operation",
  //   fixed: "right",
  //   width: 100,
  //   align: "center",
  //   slots: { customRender: "action" },
  // },
];

export default defineComponent({
  components: {},
  setup() {
    //搜索绑定数据
    const search = reactive({
      //是否显示有的搜索框
      showAll: false,

      //搜索数据
      data: {
        memberMaskId: "",
        memberId: "",
        startTime: "",
        endTime: "",
        clubId: "",
        changeType: undefined,
        gameType: undefined,
      },

      //点击搜索后的搜索数据
      relData: {},
    });

    //表格加载中
    const loading = ref(true);

    const pagination = reactive(pagConfig);

    const data = ref([]);

    const formRef = ref();

    //开始结束时间
    const validTimeRange = reactive({
      minDate: "",
      maxDate: "",
    });

    //获取不可选择的时间
    const disabledDate = (currentDate: Dayjs) => {
      return !dateIsBetween(validTimeRange.minDate, validTimeRange.maxDate, currentDate);
    };

    //首次进入，获取可选的日期范围
    const getTime = async () => {
      loading.value = true;
      const { data } = await http.get("us_club_member_hp_log/time");
      validTimeRange.minDate = data.minDate;
      validTimeRange.maxDate = data.maxDate;
      search.data.startTime = data.maxDate;
      search.data.endTime = data.maxDate;

      onSearch();
    };

    //获取列表
    const getList = async () => {
      loading.value = true;
      const result = await http.get("us_club_member_hp_log", {
        page: pagination.current,
        page_size: pagination.pageSize,
        ...search.relData,
      });

      let offset = getPageOffset(pagination.current!, pagination.pageSize!);
      data.value = result.data.data.map((item: any) => {
        item.index = ++offset;
        return item;
      });

      pagination.total = result.data.count;

      loading.value = false;
    };

    //监听页码切换
    // const handleTableChange = (pag: PaginationProps) => {
    //   pagination.current = pag?.current;
    //   pagination.pageSize = pag?.pageSize;
    //   getList();
    // };

    const handleTableChange = (pag: number, pageSize: number) => {
      pagination.current = pag;
      pagination.pageSize = pageSize;
      getList();
    };

    //点击搜索按钮
    const onSearch = () => {
      pagination.current = 1;
      search.relData = search.data;
      getList();
    };

    //点击重置按钮
    const onReset = () => {
      formRef.value.resetFields();
      search.data.startTime = validTimeRange.maxDate;
      search.data.endTime = validTimeRange.maxDate;
      onSearch();
    };

    onMounted(() => {
      getTime();
    });

    return {
      data,
      columns,
      search,
      pagination,
      formRef,
      loading,
      clubHpChangeTypes,
      gameTypes,
      handleTableChange,
      onSearch,
      onReset,
      searchCol,
      disabledDate,
      getLabelByValue,
    };
  },
});
